import gql from 'graphql-tag'


export const getDiscountCode = gql`
    query GetDiscountCode {
        getDiscountCode {
            code
        }
    }
`
