import { query } from '../../../helpers/GraphqlHelpers'

import { createPaymentWithNewSepaMandate } from './CreatePaymentWithNewSepaMandate'
import { validateSepaMandate } from './ValidateSepaMandate'
import { createSepaMandate } from './CreateSepaMandate'
import { getPaymentAlias } from './GetPaymentAlias'
import { getDiscountCode } from './GetDiscountCode'
import { checkDiscountCode } from './CheckDiscountCode'
import { RegisterPremiumWithFullDiscount } from './RegisterPremiumWithFullDiscount'
import { get3DSecureForm } from './Get3DSecureForm'


export default {
  // eslint-disable-next-line max-len
  CreatePaymentWithNewSepaMandate: (variables, headers = {}) => (query(createPaymentWithNewSepaMandate, variables, headers)),
  CreateSepaMandate: (variables, headers = {}) => (query(createSepaMandate, variables, headers)),
  ValidateSepaMandate: (variables, headers = {}) => (query(validateSepaMandate, variables, headers)),
  GetPaymentAlias: (variables, headers = {}) => (query(getPaymentAlias, variables, headers)),
  GetDiscountCode: (variables, headers = {}) => (query(getDiscountCode, variables, headers)),
  CheckDiscountCode: (variables, headers = {}) => (query(checkDiscountCode, variables, headers)),
  RegisterPremiumWithFullDiscount: (variables, headers = {}) => (query(RegisterPremiumWithFullDiscount, variables, headers)),
  Get3DSecureForm: (variables, headers = {}) => (query(get3DSecureForm, variables, headers)),
}
