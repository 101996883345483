import gql from 'graphql-tag'


export const createPaymentWithNewSepaMandate = gql`
    mutation CreatePaymentWithNewSepaMandate($input: CreatePaymentWithNewSepaMandateInput!) {
        createPaymentWithNewSepaMandate(input: $input) {
            merchantRedirectUrl
        }
    }
`

