import gql from 'graphql-tag'


export const getPaymentAlias = gql`
    query GetPaymentAlias {
        getPaymentAlias {
            url
            amount
            params {
                ACCEPTURL
                ALIAS
                ALIASPERSISTEDAFTERUSE
                EXCEPTIONURL
                LANGUAGE
                ORDERID
                PSPID
                SHASIGN
                COF_INITIATOR
                COF_TRANSACTION
                COF_SCHEDULE
                COF_RECURRING_EXPIRY
                COF_RECURRING_FREQUENCY
            }
        }
    }
`
