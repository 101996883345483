import gql from 'graphql-tag'


export const createSepaMandate = gql`
    mutation CreatePaymentWithNewSepaMandate($input: CreateSepaMandateInput!) {
        createSepaMandate(input: $input) {
            merchantRedirectUrl
        }
    }
`

