import gql from 'graphql-tag'


export const checkDiscountCode = gql`
    query CheckDiscountCode($code: String!) {
        checkDiscountCode(code: $code) {
            id
            percentage
            description
            isAmbassador
        }
    }
`
