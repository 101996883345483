import gql from 'graphql-tag'


export const validateSepaMandate = gql`
    mutation validateSepaMandate($unique_mandate_reference: String!, $order_reference: String!, $_ssi: String!) {
        validateSepaMandate(
          unique_mandate_reference: $unique_mandate_reference,
          order_reference: $order_reference,
          _ssi: $_ssi) {
            status
            statusLabel
            companyId
        }
    }
`

