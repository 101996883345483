
import gql from 'graphql-tag'


export const RegisterPremiumWithFullDiscount = gql`
  mutation registerPremiumWithFullDiscount(
    $discountId: ID!
  ) {
    registerPremiumWithFullDiscount(
        discountId: $discountId
      ) {
        company {
          isActivePremium
          is_premium
          is_subscribed
          subscription_expires_at
        }
      }
  }
`
